<!--  -->
<template>
    <div class='page-certificate-summary flex-center'>
        <component-bcg url="certificate/c1.png"></component-bcg>
        <cert-window :certId="certId" ref="certificateShare"></cert-window>
        <div class="certificate-info flex-center">
            <div class="bcg-count flex-center">
                <div class="bcg-count-txt">
                    <span class="text-mini-a text-blue-color bold t">{{'你已累计获得'}}</span>
                    <div class="count flex-center">
                        <span class="text-mini-a bold c">{{existCount}}</span>
                    </div>
                    <span class="text-mini-a text-blue-color bold t">{{'张奖状'}}</span>
                </div>
            </div>
            <div class="bcg-rule flex-center" @click="openRule()">
                <div class="bcg-rule-txt">
                    <span class="text-mini-a flex-center text-blue-color bold t">{{'规'}}</span>
                    <span class="text-mini-a flex-center text-blue-color bold t">{{'则'}}</span>
                </div>
            </div>
        </div>
        <div class="bcg">
            <div class="bcg-light"></div>
            <div class="bcg-box">
                <div class="head">
                    <div class="head-title">
                        <span class="text-normal-c bold flex-center text-red-color">{{'我 的 奖 状'}}</span>
                    </div>
                </div>
                <div class="items-box mini-scrollbar"
                @scroll="scrolLoad"
                ref="commentContent" 
                v-if="existCount">
                    <div class="items " ref="commentBox">
                        <div class="item-li" v-for="(v,i) in certList" :key="i" v-show="certListCul">
                            <div class="box">
                                <div class="item" v-for="(t,j) in v" :key="j" >
                                    <div class="item-shadow" @click="openCert(t)">
                                        <div class="img-box">
                                            <div class="img" :class="{'filter':!t.status}">
                                                <div class="cert">
                                                    <cert :info="t"></cert>
                                                </div>
                                            </div>
                                            <div class="shadow-mark" v-if="!t.status"></div>
                                            <div class="img-mark" v-if="!t.status"></div>
                                        </div>
                                        <div class="get-info">
                                            <span class="text-mini bold level">{{t.certificateName}}</span>
                                            <span class="text-mini bold time">{{t.date}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="shelf"></div>
                        </div>
                        <loading :loading.sync="loading" color="#9A4927"></loading>
                    </div>
                </div>
                <div class="empty flex-center" v-if="!existCount&&loadingFlag">
                    <span class="text-normal-a text-r-color flex-center bold">{{'目前还没有获得证书'}}</span>
                    <span class="text-normal-a text-r-color flex-center bold two-txt">{{'快去完成作业以获得证书吧～'}}</span>
                    <div class="btn flex-center">
                        <confirmPlay class="task" :type="`primary`" @click="goTask">
                            <span class="text-mini flex-center bold rule-txt">{{"去完成作业"}}</span>
                        </confirmPlay>
                        <confirmPlay class="rule" @click="openRule()">
                            <span class="text-mini flex-center bold rule-txt">{{"查看证书获得规则"}}</span>
                        </confirmPlay>
                    </div>
                </div>
            </div>
        </div>
        <rule ref="rule"></rule>
    </div>
</template>

<script>
    import apiClass from '@/API/page/certificate'
    import ComponentBcg from '../../../components/componentBcg.vue'
    import cert from '@/components/certificate/cert.vue'
    import rule from '../components/rule.vue'
    import loading from '../components/loading.vue'
    export default {
        name: '',
        props: {
        },
        data() {
            return {
                api: new apiClass(),
                certList: [],
                existCount: 0,
                total: 0,
                loading: false,
                page: 0,
                size: 12,
                certId: 0,
                dataLength: null,
                loadingFlag: false,
            };
        },
        methods: {
            openRule(){
                this.$refs['rule'].open()
            },
            goTask(){
                this._routeHand.goto({
                    name: 'task',
                    type: 'push',
                    query: {backName: 'certificate'}
                })
            },
            async openCert(val){
                let {id,status} = val || {}
                if(!status) return this._common.confirmPro.open({
                    message: '目前还没有获得该证书',
                    confirmType: 'c1',
                })
                this.certId = id
                let res = await this.getCertificate(id)
                this.$refs['certificateShare'].open([res])
            },
            async getCertificate(val){
                let {usercode:user_code} = this
                let opt ={
                    certificate_id: val,
                    user_code,
                }
                let res = await this.api.getCertificate(opt)
                return res
            },
            async getCertificateList(){
                this.loadingFlag = false
                let {usercode: user_code ,page ,size} = this
                let opt = {
                    user_code,
                    page,
                    size,
                }
                let res = await this.api.getCertificateList(opt)
                if(res){
                    this.loadingFlag = true
                    let {sumCount, existCount, certificateList:list} = res || {}
                    this.total = sumCount
                    this.dataLength = list.length
                    this.existCount = existCount
                    this.certList = this.dataHandle(list)
                }
            },
            // cards数组分割成N个
            splitArr(arr,N){
                var result = []
                for (let i = 0; i < arr.length; i+=N) {
                    result.push(arr.slice(i,i+N))
                }
                return result
            },
            async scrolLoad(){
                let {loading, usercode: user_code, page, size, dataLength, total} = this
                if(loading) return
                if(!this.$refs['commentContent']) return
                let index = this.$refs['commentContent'].scrollTop
                let contentHeight = this.$refs['commentContent'].offsetHeight
                let boxHeight = this.$refs['commentBox'].offsetHeight
                if((boxHeight - index + 132) > contentHeight) return
                this.loading = true
                if(dataLength==total) return this.loading = false
                page++
                let res = await this.api.getCertificateList({user_code, page, size, noLoad: true})
                if(res){
                    let {certificateList:list} = res || []
                    if(list.length){
                        this.page = page
                        this.dataLength = dataLength + list.length
                        let r = this.dataHandle(list)
                        this.certList = this.certList.concat(r)
                    }
                }
                this.loading = false
            },
            dataHandle(val){
                let list = val || []
                let r = []
                for (const i of list) {
                    let {status,townCertificate,certificateTime} = i
                    let {id,certificateName,certificateLevel} = townCertificate
                    let date = ""
                    if(status){
                        let {day,month,year} = certificateTime.date
                        if(month<10) month = '0'+ month
                        if(day<10) day = '0' + day
                        date = `${year+'.'+month+'.'+day}获得`//日期
                    }else{
                        date = "当前未获得"
                    }
                    let info = {
                        date,
                        status,
                        id,
                        certificateName:certificateName + "级奖状",
                        certificateLevel:certificateLevel + "级",
                        level: certificateLevel,
                    }
                    r.push(info)
                }
                return this.splitArr(r,4)
            },
        },
        created() {
            this.getCertificateList()
        },
        components: {
            ComponentBcg,
            cert,
            rule,
            loading,
        },
        computed: {
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            certListCul(){
                let {certList:list} = this
                let rd = false
                if(list.length) rd = true
                return rd
            },
            certListLength(){
                let {certList} = this
                return certList.length
            },
        },
        watch: {
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' scoped src="./summary.less"></style>