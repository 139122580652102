<!--  -->
<template>
    <div class='loading-com'>
        <van-loading 
        v-show="loading"
        type="spinner"  
        :color="loadingColor"
        />
        <span class="txt bold" :style="{color:loadingColor}" v-if="loading">{{loadingText}}</span>
        <span class="txt bold" :style="{color:loadingColor}" v-else>{{loadedText}}</span>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            loadingText: {
                type: String,
                default: '加载中...'
            },
            loadedText: {
                type: String,
                default: '加载完成'
            },
            loading: Boolean,
            color: String,
        },
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            loadingColor(){
                let {color} = this
                return color
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .loading-com{
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 31px;
        width: 100%;
        .txt{
            // color: #D2B59A;
            font-size: 12px;
            transform: scale(0.8);
        }
    }
</style>