<!-- -->
<template>
    <div class='page-certificate-rule flex-center' v-if="show">
        <div class="bcg">
            <div class="mark" @click="close()"></div>
            <div class="d1">
                <div class="off-btn" @click="close()"></div>
                <div class="stone-record-title flex-center">
                    <span class="text-normal bold text-purple-color">{{"规则"}}</span>
                </div>
                <div class="items mini-scrollbar" 
                ref="commentContent"
                >
                    <div ref="commentBox">
                        <div class="item" v-for="(v,i) in ruleList" :key="i">
                            <div class="con">
                                <!-- <span class="stone-record-size bold text-brown-color">{{v.chapter}}&emsp;{{v.level}}</span> -->
                                <span :class="{t:i==0}" class="stone-record-size bold text-brown-color">{{v.chapter}}</span>
                                <span class="stone-record-size bold text-brown-color r">{{v.level}}</span>
                            </div>
                            <div class="d3" v-if="i!=11"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
        },
        data() {
            return {
                ruleList:[
                    {chapter: "课后作业可以体现你学习的成果，衡量你是否达到相应的围棋水平。完成作业，并达成90%以上的正确率（可订正后重复提交），说明你充分掌握了本课知识，达到了对应的围棋水平，收获由高途围棋教研组评定的围棋水平证书。具体评定规则如下："},
                    {chapter: "G0",level: "25级"},
                    {chapter: "G1第1、2讲",level: "24级"},
                    {chapter: "G1第3、4讲",level: "23级"},
                    {chapter: "G1第5、6讲",level: "22级"},
                    {chapter: "G1第7、8讲",level: "21级"},
                    {chapter: "G1第9讲 期中测评",level: "20级"},
                    {chapter: "G1第10、11、12讲",level: "19级"},
                    {chapter: "G1第13、14、15讲",level: "18级"},
                    {chapter: "G1第16、17、18讲",level: "17级"},
                    {chapter: "G1第19、20、21讲",level: "16级"},
                    {chapter: "G1第22讲 期末测评",level: "15级"},
                    {chapter: "（预估达到，考前再冲刺）"},
                ],
                show: false,
            };
        },
        methods: {
            open(){
                this.show = true
            },
            close(){
                this.show = false
            },
        },
        created() {
        },
        components: {
        },
        computed: {
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
        },
        watch: {
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' src="./rule.less"></style>